.pay-now-button {
    display: block;
    padding: 13px 24px;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 500;
    border: none;
    background: #3e5fff;
    color: white;
    touch-action: manipulation;
    transition-timing-function: cubic-bezier(0, 0, .2, 1);
    border-radius: 9999px;
    cursor: pointer;
}

#payment-message {
    color: #df1b41;
}