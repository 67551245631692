.tripayer-component-options {
    display: flex;
    flex-direction: column;
    padding: 100px;
}

.tripayer-component-option {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 12px;
    cursor: pointer;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(226, 226, 228, 1);
    user-select: none;
    margin-top: 8px;
    margin-bottom: 8px;
}

.tripayer-component-option:hover {
    border-color: rgb(47, 47, 192);
}

.tripayer-component-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    text-align: left;
    padding-left: 24px;
    padding-right: 24px;

}

.tripayer-component-option-child {
    display: flex;
    align-items: center;
}

.tripayer-component-option-text {
    margin-right: 15px;
}

.tripayer-component-content {
    border-top: 1px solid rgba(226, 226, 228, 1);
    padding: 20px;
    text-align: start;
}

.tripayer-AccordionButton_chevron.active {
    transform: rotate(90deg);
}

.tripayer-component-option-icons {
    display: flex;
    align-items: center;
}

.tripayer-component-option-icon-image {
    margin-left: 4px;
    margin-right: 4px;
}

.tripayer-component-accordion {
    display: flex;
    align-items: center;

}

.tripayer-component-accordion-extra {
    font-size: 13px;
}

.spiner_parent{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}


.spiner_container {
    --uib-size: 45px;
    display: block;
    --uib-color: rgb(12, 75, 192);
    --uib-speed: 2s;
    position: relative;
    height: var(--uib-size);
    width: var(--uib-size);
}

.spiner_container::before,
.spiner_container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    border-radius: 50%;
    background-color: var(--uib-color);
    animation: pulse var(--uib-speed) linear infinite;
    transform: scale(0);
    opacity: 0;
    transition: background-color 0.3s ease;
}

.spiner_container::after {
    animation-delay: calc(var(--uib-speed) / -2);
}

@keyframes pulse {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}